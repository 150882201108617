@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
  background-color: #f7f9f9;
}

.ourTeam {
  background-image: url(/src/assests/images/our-team.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.projects {
  background-image: url(/src/assests/images/projects.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: background-size ease-in-out 0.5s;
  -moz-transition: background-size ease-in-out 0.5s;
  -ms-transition: background-size ease-in-out 0.5s;
  -o-transition: background-size ease-in-out 0.5s;
  transition: background-size ease-in-out 0.5s;

  transition: background-position ease 0.3s;
}
.bg-image:hover {
  background-position: left;
}

.image-one {
  background-image: url(/src/assests/images/image-1.jpg);
}
.image-two {
  background-image: url(/src/assests/images/image-2.jpg);
}
.image-three {
  background-image: url(/src/assests/images/image-3.jpg);
}
.image-four {
  background-image: url(/src/assests/images/image-4.jpg);
}

.team-grid {
  display: grid;
  /* align-items: stretch; */
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  column-gap: 1rem;
  padding: 1rem;
}

@media (min-width: 578px) {
  .team-grid {
    grid-template-rows: 400px;
  }
}

@media (min-width: 992px) {
  .team-grid {
    align-items: stretch;

    grid-template-columns: 200px 1fr;
    grid-template-rows: 300px;
  }
}
@media (min-width: 1140px) {
  .team-grid {
    align-items: stretch;

    grid-template-columns: 200px 1fr;
    grid-template-rows: 240px;
  }
}
