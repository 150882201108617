.nav-btn {
  display: flex;
  flex-direction: column;
  width: 1.6rem;
  gap: 7px;
  cursor: pointer;
}
.nav-btn > span {
  display: block;
  width: 100%;
  height: 1.7px;
  background-color: #666;
  transition: all ease 0.3s;
}

.nav-btn > span:nth-child(2) {
  width: 75%;
  margin-left: auto;
}
.nav-btn:hover span:nth-child(2) {
  width: 100%;
  margin-left: auto;
}

.nav-btn.active span:nth-child(2) {
  display: none;
  margin: 0;
}
