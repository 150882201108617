.Container {
  width: min(100% - 2.4rem, 120rem);
  margin-inline: auto;
}

@media (min-width: 768px) {
  .Container {
    width: min(100% - 9.6rem, 120rem);
  }
}
